import { useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import { pricesApi } from '../../../api/prices';
import CategoryForm from './forms/CategoryForm';
import ServiceForm from './forms/ServiceForm';
import 'react-responsive-modal/styles.css';
import { v4 as uuidv4 } from 'uuid';

export default function AdminPrices() {
  const [currentFile, setCurrentFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingService, setEditingService] = useState(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingCategory(null);
    setEditingService(null);
  };

  const handleAddCategory = () => {
    setEditingCategory({
      id: uuidv4(),
      name: ''
    });
    setIsModalOpen(true);
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setIsModalOpen(true);
  };

  const handleAddService = (categoryId) => {
    setEditingService({
      id: uuidv4(),
      category_id: categoryId,
      name: '',
      price: '',
      code: ''
    });
    setIsModalOpen(true);
  };

  const handleEditService = (service) => {
    setEditingService(service);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        await fetchCurrentFile();
        await fetchCategories();
      } catch (error) {
        console.error('Ошибка инициализации:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    
    init();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await pricesApi.getAllCategories();
      const processedCategories = data.map(category => ({
        ...category,
        services: typeof category.services === 'string' 
          ? JSON.parse(category.services)
          : category.services
      }));
      setCategories(processedCategories);
    } catch (error) {
      console.error('Ошибка при получении категорий:', error);
      setError(error.message);
    }
  };

  const fetchCurrentFile = async () => {
    try {
      const fileInfo = await pricesApi.getPriceFileInfo();
      setCurrentFile(fileInfo);
    } catch (error) {
      console.error('Ошибка при получении информации о файле:', error);
    }
  };

  const handleSaveCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const category = {
      id: editingCategory.id,
      name: formData.get('name')
    };

    try {
      if (editingCategory.order_index !== undefined) {
        await pricesApi.updateCategory(category.id, category);
      } else {
        await pricesApi.createCategory(category);
      }
      await fetchCategories();
      handleCloseModal();
    } catch (error) {
      console.error('Ошибка при сохранении категории:', error);
      setError(error.message);
    }
  };

  const handleSaveService = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    
    const service = {
      id: editingService.id,
      category_id: editingService.category_id,
      name: formData.get('name'),
      price: formData.get('price'),
      code: formData.get('code')
    };

    try {
      if (!editingService.order_index) {
        await pricesApi.createService(service);
      } else {
        await pricesApi.updateService(service.id, service);
      }
      await fetchCategories();
      handleCloseModal();
    } catch (error) {
      console.error('Ошибка при сохранении услуги:', error);
      setError(error.message);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (!window.confirm('Удалить раздел и все его услуги?')) return;

    try {
      await pricesApi.deleteCategory(categoryId);
      await fetchCategories();
    } catch (error) {
      console.error('Ошибка при удалении категории:', error);
      setError(error.message);
    }
  };

  const handleDeleteService = async (serviceId) => {
    if (!window.confirm('Удалить услугу?')) return;

    try {
      await pricesApi.deleteService(serviceId);
      await fetchCategories();
    } catch (error) {
      console.error('Ошибка при удалении услуги:', error);
      setError(error.message);
    }
  };

  const handleUploadPrice = async () => {
    if (currentFile) {
      const confirmUpload = window.confirm(
        'Уже существует загруженный прайс-лист. Хотите заменить его новым файлом?'
      );
      if (!confirmUpload) return;
    }

    try {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.pdf';
      
      input.onchange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
console.log('file',file);
        const formData = new FormData();
        formData.append('priceFile', file);

        await pricesApi.uploadPriceFile(formData);
        await fetchCategories();
        alert('Прайс-лист успешно загружен');
      };

      input.click();
    } catch (error) {
      console.error('Ошибка при загрузке прайс-листа:', error);
      setError(error.message);
    }
  };

  if (loading) return <div>Загрузка...</div>;
  if (error) return <div>Ошибка: {error}</div>;

  return (
    <div className="prices-manager">
      <h2>Управление прайс-листом</h2>
      
      <div className="prices-actions">
        <button 
          className="upload-price-button"
          onClick={handleUploadPrice}
        >
          Загрузить полный прайс
        </button>
        <button className="add-button" onClick={handleAddCategory}>
          Добавить категорию
        </button>
      </div>

      {categories.map(category => (
        <div key={category.id} className="price-category">
          <div className="category-header">
            <h3>{category.name}</h3>
            <div className="category-actions">
              <button onClick={() => handleEditCategory(category)}>
                Редактировать
              </button>
              <button onClick={() => handleAddService(category.id)}>
                Добавить услугу
              </button>
              <button 
                onClick={() => handleDeleteCategory(category.id)}
                className="delete-button"
              >
                Удалить раздел
              </button>
            </div>
          </div>

          <div className="services-list">
            {category.services && category.services.length > 0 ? (
              category.services.map(service => (
                <div key={service.id} className="service-item">
                  <div className="service-info">
                    <span className="service-code">{service.code}</span>
                    <span className="service-name">{service.name}</span>
                    <span className="service-price">{service.price} ₽</span>
                  </div>
                  <div className="service-actions">
                    <button onClick={() => handleEditService({...service, category_id: category.id})}>
                      Редактировать
                    </button>
                    <button 
                      onClick={() => handleDeleteService(service.id)}
                      className="delete-button"
                    >
                      Удалить
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>Нет услуг в данной категории</p>
            )}
          </div>
        </div>
      ))}

      <Modal 
        open={isModalOpen} 
        onClose={handleCloseModal}
        center
      >
        {editingCategory && (
          <CategoryForm
            category={editingCategory}
            onSave={handleSaveCategory}
            onCancel={handleCloseModal}
          />
        )}
        {editingService && (
          <ServiceForm
            service={editingService}
            onSave={handleSaveService}
            onCancel={handleCloseModal}
          />
        )}
      </Modal>
    </div>
  );
} 
