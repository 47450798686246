import { API_CONFIG } from './config';

export const pricesApi = {
  getPriceFileInfo: () => 
    API_CONFIG.fetchApi('/api/prices/files/info'),

  getAllCategories: () => 
    API_CONFIG.fetchApi('/api/prices'),

  createCategory: (category) =>
    API_CONFIG.fetchApi('/api/prices/categories', {
      method: 'POST',
      body: JSON.stringify(category)
    }),

  updateCategory: (id, category) =>
    API_CONFIG.fetchApi(`/api/prices/categories/${id}`, {
      method: 'PUT',
      body: JSON.stringify(category)
    }),

  deleteCategory: (id) =>
    API_CONFIG.fetchApi(`/api/prices/categories/${id}`, {
      method: 'DELETE'
    }),

  createService: (service) =>
    API_CONFIG.fetchApi('/api/prices/services', {
      method: 'POST',
      body: JSON.stringify(service)
    }),

  updateService: (id, service) =>
    API_CONFIG.fetchApi(`/api/prices/services/${id}`, {
      method: 'PUT',
      body: JSON.stringify(service)
    }),

  deleteService: (id) =>
    API_CONFIG.fetchApi(`/api/prices/services/${id}`, {
      method: 'DELETE'
    }),

  uploadPriceFile: (formData) =>
    API_CONFIG.fetchApi('/api/prices/files/upload', {
      method: 'POST',
      headers: {}, // Очищаем заголовки для FormData
      body: formData,
      useDefaultHeaders: false
    }),

  getCurrentPriceFile: () =>
    API_CONFIG.fetchApi('/api/prices/files/current', {
      headers: {
        Accept: 'application/pdf'
      }
    })
};
